import React from 'react';
import LightbulbCircleIcon from '@mui/icons-material/LightbulbCircle';
import { Link } from 'react-router-dom';
const SecondBanner = () => {

    const scroll = () =>{
        window.scrollTo(0,0);
    }
    return (
        <div className='blogSecond'>
            <div className='secondBlogContent'>
                <p className='name'>
                    Have a look at our blog
                </p>
            </div>
            <div className='secondBlogButtons'>
                <Link onClick={scroll} to='/contact'><button className='button1'><LightbulbCircleIcon/> join us</button></Link>
                <Link onClick={scroll} to='/appointment'><button className='button2'>Book Appointment &rarr;</button></Link>
            </div>
        </div>
    );
};

export default SecondBanner;