import React from 'react';
import Contact from '../HomePages/Contact';
import ContactBanner from './ContactBanner';
import QuestionAnswer from './QuestionAnswer';
import WhereFind from './WhereFind';

const ContactUs = () => {
    return (
        <div>
            <ContactBanner></ContactBanner>
            <QuestionAnswer></QuestionAnswer>
            <WhereFind></WhereFind>
            <Contact></Contact>
        </div>
    );
};

export default ContactUs;