import React from 'react';
import './TeamSection.css';
import TeamMember from './TeamMember';
import OurQuality from '../HomePages/OurQuality';

const TeamSection = () => {
    return (
        <div className='mainTeamsection'>
           <OurQuality></OurQuality>
            {/* <TeamMember></TeamMember> */}
        </div>
    );
};

export default TeamSection;