import React from 'react';
import './CustomStyle.css';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import { Link } from 'react-router-dom';

const Banner = () => {

    const scroll = () =>{
        window.scrollTo(0,0);
    }
    return (
        <div className='banner'>
            <div className='bannerHeading'>
                {/* <p>  <span>welcome</span> </p> */}
                <h2>Zero Physiotherapy Clinic</h2>
            </div>
            <div className='bannerButtons'>
                <Link onClick={scroll} to='/appointment'><button className='button1'>Book Appoinment &rarr;</button></Link>
                <Link onClick={scroll} to='/blog'><button className='button2'><PlayCircleOutlinedIcon/> see how we work</button></Link>
            </div>
        </div>
    );
};

export default Banner;