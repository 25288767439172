import React from 'react';
import "./AboutSection.css";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const Features = () => {
    return (
        <div className='feature'>
            <div className='container'>
                <div className='aboutStory'>
                    <div className='head'>
                        <p className='name'>About <span> us</span></p>
                    </div>
                    <div className='aboutMain'>
                        <p className='title'>Our Story</p>
                        <p className='sortDesc'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia et inventore incidunt ullam deserunt voluptate? Nam expedita fugiat hic eveniet?</p>
                        <p className='fullDesc'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore officia doloremque accusamus provident recusandae maxime nostrum placeat laudantium. Perspiciatis soluta blanditiis necessitatibus voluptate iusto est atque culpa maiores quasi quas ad dignissimos rerum porro quos sed, vero nam excepturi asperiores eveniet accusamus. Aliquam aspernatur repellat pariatur error accusamus magnam consectetur!</p>
                    </div>
                </div>
                <div className='allFeatures'>
                    <div className='details'>
                        <p className='heading'>WHY WE'RE THE BEST</p>
                    </div>
                    <div className='glance'>
                        <p className='title'>Our Features at a Glance</p>
                        <div className='points'>
                            <p className='point'><CheckCircleOutlineIcon className='icon' /> No. 1 GCR accredit Physiotherapy Center in Bangladesh​</p>
                            <p className='point1'><CheckCircleOutlineIcon className='icon' /> All qualified physiotherapists’ and experts are available</p>
                            <p className='point'><CheckCircleOutlineIcon className='icon' /> Manual Therapy Based Physiotherapy Clinic​</p>

                            <p className='point1'><CheckCircleOutlineIcon className='icon' /> Neat, Clean and Air-conditioned Treatment Rooms</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Features;