import React from 'react';
import LightbulbCircleIcon from '@mui/icons-material/LightbulbCircle';
import JustGallery from './JustGallery';
import { Link } from 'react-router-dom';

const Banner2 = () => {
    return (
        <>
        <div className='secondBanner'>
            <div className='heading'>
                <p>Have a look at our work place</p>
            </div>
            <div className='secondContent'>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur fugit earum quos hic praesentium eveniet iste reprehenderit provident debitis animi.</p>
            </div>
            <div className='secondBannerButtons'>
                <Link to='/contact'><button>Join Us <LightbulbCircleIcon className='icon'/></button></Link>
            </div>
            <div className='secondBannerButtons2'>
                <Link to='/appointment'><button>Book On Appointment</button></Link>
            </div>
        </div>
        <JustGallery></JustGallery>
        </>
    );
};

export default Banner2;