import React from 'react';
import './GallerySection.css';

const Banner = () => {
    return (
        <div className='galleryBanner'>
            <p className='name'>Our Gallery</p>
        </div>
    );
};

export default Banner;