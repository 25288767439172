import React from 'react';
import OurPackages from './OurPackages';
import TimeShedule from './TimeShedule';
import WeServe from '../HomePages/WeServe';

const ServiceSection = () => {
    return (
        <div>
            <TimeShedule></TimeShedule>
            <WeServe></WeServe>
            <OurPackages></OurPackages>
        </div>
    );
};

export default ServiceSection;