import React from 'react';
import './ServiceSection.css';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Link } from 'react-router-dom';

const OurPackages = () => {

    const scroll = () => {
        window.scrollTo(0, 0);
      };
    return (
        <div className='packages'>
            <div className='container'>
                <div className='heading'>
                    {/* <p className='title'>Session Charges</p> */}
                    <p className='name'>Choose Your Package</p>
                </div>
                <div className='package'>
                    <div className='row'>
                        <div className='col-12 col-md-6 col-lg-4 col-xl-4'>
                            <div className='priceBox'>
                                <div className='priceHeading'>
                                    <h1>Per Session</h1>
                                    <p>Daily Package</p>
                                </div>
                                <div className='prices'>
                                    <p><sup>TK.</sup></p>
                                    <p className='amount'>700</p>
                                    <p><sup>Daily</sup></p>
                                </div>
                                <p className='priceName'>
                                    <CheckCircleOutlineIcon className='icon' /> Clinic Outdoor
                                </p>
                                <Link onClick={scroll} to="/appointment">
                                <button>
                                    Book Now
                                </button>
                                </Link>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4 col-xl-4'>
                            <div className='priceBox priceBox1 shadow'>
                                <p className='sticker'>Popular</p>
                                <div className='priceHeading'>
                                    <h1>7 Days</h1>
                                    <p>Weekly Package</p>
                                </div>
                                <div className='prices'>
                                    <p><sup>TK.</sup></p>
                                    <p className='amount'>4500</p>
                                    <p><sup>Weekly</sup></p>
                                </div>
                                <p className='priceName'>
                                    <CheckCircleOutlineIcon className='icon' /> Clinic Outdoor
                                </p>
                                <Link onClick={scroll} to="/appointment">
                                <button>
                                    Book Now
                                </button>
                                </Link>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4 col-xl-4'>
                            <div className='priceBox'>
                                <div className='priceHeading'>
                                    <h1>15 Days</h1>
                                    <p>Half Monthly Package</p>
                                </div>
                                <div className='prices'>
                                    <p><sup>TK.</sup></p>
                                    <p className='amount'>9000</p>
                                    <p><sup>Half Month</sup></p>
                                </div>
                                <p className='priceName'>
                                    <CheckCircleOutlineIcon className='icon' /> Clinic Outdoor
                                </p>
                                <Link onClick={scroll} to="/appointment">
                                <button>
                                    Book Now
                                </button>
                                </Link>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4 col-xl-4'>
                            <div className='priceBox'>
                                <div className='priceHeading'>
                                    <h1>30 Days</h1>
                                    <p>Monthly Package</p>
                                </div>
                                <div className='prices'>
                                    <p><sup>TK.</sup></p>
                                    <p className='amount'>17000</p>
                                    <p><sup>Monthly</sup></p>
                                </div>
                                <p className='priceName'>
                                    <CheckCircleOutlineIcon className='icon' /> Clinic Outdoor
                                </p>
                                <Link onClick={scroll} to="/appointment">
                                <button>
                                    Book Now
                                </button>
                                </Link>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4 col-xl-4'>
                            <div className='priceBox priceBox1 shadow'>
                                
                                <div className='priceHeading'>
                                    <h1>Per Session</h1>
                                    <p>Daily Package</p>
                                </div>
                                <div className='prices'>
                                    <p><sup>TK.</sup></p>
                                    <p className='amount'>1400</p>
                                    <p><sup>Daily</sup></p>
                                </div>
                                <p className='priceName'>
                                    <CheckCircleOutlineIcon className='icon' /> Home Service
                                </p>
                                <Link onClick={scroll} to="/appointment">
                                <button>
                                    Book Now
                                </button>
                                </Link>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4 col-xl-4'>
                            <div className='priceBox'>
                                <div className='priceHeading'>
                                    <h1>7 Days</h1>
                                    <p>Weekly Package</p>
                                </div>
                                <div className='prices'>
                                    <p><sup>TK.</sup></p>
                                    <p className='amount'>9500</p>
                                    <p><sup>Weekly</sup></p>
                                </div>
                                <p className='priceName'>
                                    <CheckCircleOutlineIcon className='icon' /> Home Service
                                </p>
                                <Link onClick={scroll} to="/appointment">
                                <button>
                                    Book Now
                                </button>
                                </Link>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4 col-xl-4'>
                            <div className='priceBox priceBox1'>
                            <p className='sticker'>Popular</p>
                                <div className='priceHeading'>
                                    <h1>15 Days</h1>
                                    <p>Half Monthly Package</p>
                                </div>
                                <div className='prices'>
                                    <p><sup>TK.</sup></p>
                                    <p className='amount'>18000</p>
                                    <p><sup>Half Month</sup></p>
                                </div>
                                <p className='priceName'>
                                    <CheckCircleOutlineIcon className='icon' /> Home Service
                                </p>
                                <Link onClick={scroll} to="/appointment">
                                <button>
                                    Book Now
                                </button>
                                </Link>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4 col-xl-4'>
                            <div className='priceBox shadow'>
                                <div className='priceHeading'>
                                    <h1>30 Days</h1>
                                    <p>Monthly Package</p>
                                </div>
                                <div className='prices'>
                                    <p><sup>TK.</sup></p>
                                    <p className='amount'>35000</p>
                                    <p><sup>Monthly</sup></p>
                                </div>
                                <p className='priceName'>
                                    <CheckCircleOutlineIcon className='icon' /> Home Service
                                </p>
                                <Link onClick={scroll} to="/appointment">
                                <button>
                                    Book Now
                                </button>
                                </Link>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4 col-xl-4'>
                            <div className='priceBox priceBox1'>
                                <div className='priceHeading'>
                                    <h1>Custom Package</h1>
                                    {/* <p>Custom Package</p> */}
                                </div>
                                <div className='prices'>
                                    {/* <p><sup>TK.</sup></p> */}
                                    <p className='amount'>On Discussion</p>
                                    {/* <p className='amount'>30000</p> */}
                                    {/* <p><sup>Daily</sup></p> */}
                                </div>
                                <p className='priceName'>
                                    <CheckCircleOutlineIcon className='icon' /> Clinic Outdoor
                                </p>
                                <Link onClick={scroll} to="/appointment">
                                <button>
                                    Book Now
                                </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default OurPackages;