import React from 'react';
import Contact from '../HomePages/Contact';
import PeopleSays from '../HomePages/PeopleSays';
import Features from './Features';

const AboutSection = () => {
    return (
        <div className=''>
            <Features></Features>
           <div className='mainAbout'>
           <PeopleSays></PeopleSays>
           <Contact></Contact>
           </div>
        </div>
    );
};

export default AboutSection;