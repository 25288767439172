import React from 'react';
import FAQ from '../BlogPages/FAQ';

const QuestionAnswer = () => {
    return (
        <div className='contactQA'>
            <div className='container'>
                <div className='columsFaq'>
                    <div className='row'>
                        <div className='col-12 col-lg-5'>
                            <div className='firstContents'>
                                <div className='firstCol'>
                                    <p className="title">F.A.Q.</p>
                                    <p className='name'>Frequently Asked</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-7'>
                            <div className='secondCol'>
                                <div className='faqContent'>
                                    <details>
                                        <summary>How do I create accordion?</summary>
                                        <hr />
                                        <div className='mainFaq'>
                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque voluptatibus suscipit repellendus laudantium nemo incidunt aperiam, placeat vitae explicabo blanditiis.
                                            </p>
                                        </div>
                                    </details>
                                </div>
                                <div className='faqContent'>
                                    <details>
                                        <summary>How do I create accordion?</summary>
                                        <hr />
                                        <div className='mainFaq'>
                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque voluptatibus suscipit repellendus laudantium nemo incidunt aperiam, placeat vitae explicabo blanditiis.
                                            </p>
                                        </div>
                                    </details>
                                </div>
                                <div className='faqContent'>
                                    <details>
                                        <summary>How do I create accordion?</summary>
                                        <hr />
                                        <div className='mainFaq'>
                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque voluptatibus suscipit repellendus laudantium nemo incidunt aperiam, placeat vitae explicabo blanditiis.
                                            </p>
                                        </div>
                                    </details>
                                </div>
                                <div className='faqContent'>
                                    <details>
                                        <summary>How do I create accordion?</summary>
                                        <hr />
                                        <div className='mainFaq'>
                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque voluptatibus suscipit repellendus laudantium nemo incidunt aperiam, placeat vitae explicabo blanditiis.
                                            </p>
                                        </div>
                                    </details>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default QuestionAnswer;