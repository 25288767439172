import React from 'react';
import FAQ from './FAQ';
import SecondBanner from './SecondBanner';

const BlogSection = () => {
    return (
        <div>
            <SecondBanner></SecondBanner>
            <FAQ></FAQ>
        </div>
    );
};

export default BlogSection;