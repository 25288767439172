import React from 'react';
import { Link } from 'react-router-dom';

const Contact = () => {

    const scroll = () => {
        window.scrollTo(0, 0);
      };

    return (
        <div className='contactHome'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-6 col-lg-6 col-xl-6'>
                        <p className='addressTitle'>Address Info :</p>
                        <div className='contactAddress'>
                           <div>
                           <p>Address : House : 42, Lake Drive Road, Sector-7, <br /> Uttara, Dhaka-1230</p>
                            <p>Opening Hour : 9am – 10pm</p>
                            <p>Hotline : 45843758478</p>
                            <p>Mail : example@gmail.com</p>
                            <Link onClick={scroll} to='/appointment'><button className='ContactButton'>Booking Now</button></Link>
                           </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 col-lg-6 col-xl-6'>
                        <p className='addressTitle'>Location Map</p>
                        <div className='contactMap'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d912.0983122321464!2d90.38670342922897!3d23.875670199033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c5bd946664ff%3A0xe30ae8fd1607aae!2sZeroDevs!5e0!3m2!1sen!2sbd!4v1661867024233!5m2!1sen!2sbd" className='w-full h-full' style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;