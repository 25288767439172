import React from 'react';
import hard from '../images/hardDesses.jpg';

const FAQ = () => {
    return (
        <div className='faq'>
            <div className='head container'>
                <p className='text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio nobis eos minus numquam quasi sequi similique voluptate, molestias quis nostrum?</p>
            </div>
            <div className='faqContent'>
                <details>
                    <summary>How do I create accordion?</summary>
                    <hr />
                    <div className='mainFaq'>
                        <div className='faqImage'>
                            <img src={hard} alt="" />
                        </div>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque voluptatibus suscipit repellendus laudantium nemo incidunt aperiam, placeat vitae explicabo blanditiis.
                        </p>
                    </div>
                </details>
            </div>
            <div className='faqContent'>
                <details>
                    <summary>How do I create accordion?</summary>
                    <hr />
                    <div className='mainFaq'>
                        <div className='faqImage'>
                            <img src={hard} alt="" />
                        </div>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque voluptatibus suscipit repellendus laudantium nemo incidunt aperiam, placeat vitae explicabo blanditiis.
                        </p>
                    </div>
                </details>
            </div>
            <div className='faqContent'>
                <details>
                    <summary>How do I create accordion?</summary>
                    <hr />
                    <div className='mainFaq'>
                        <div className='faqImage'>
                            <img src={hard} alt="" />
                        </div>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque voluptatibus suscipit repellendus laudantium nemo incidunt aperiam, placeat vitae explicabo blanditiis.
                        </p>
                    </div>
                </details>
            </div>
        </div>
    );
};

export default FAQ;