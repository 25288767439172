import React from 'react';
import { Link } from 'react-router-dom';

const TimeShedule = () => {

    const scroll = () =>{
        window.scrollTo(0,0);
    }
    return (
        // <div className='timeShedule'>
        //     <div className='container'>
        //         <div className='heading'>
        //             <p className='title'>Ready To Say Goodbye To Pain ?</p>
        //             <button>setup a <span>session</span></button>
        //         </div>
        //     </div>
        //     <div className='timingSection'>
        //         <div className='content'>
        //             <p className='text'>WE ARE OPEN EVERYDAY</p>
        //             <p className='time'>9.00AM - 10.00PM</p>
        //         </div>
        //     </div>
            
        // </div>
        <div className='timeSheadule'>
            <div className="content">
               <p> Ready to say <br />
                Goodbye To Pain?</p>
            </div>
           <div className='sheduleButton'>
           <button><Link onClick={scroll} className='decoration' to='/contact'>setup & session</Link></button>
           </div>
            <p className='text'>we are open every day</p>
            <p className='time'>9:00 AM - 10:00 PM</p>
        </div>
    );
};

export default TimeShedule;