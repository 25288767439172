import React from 'react';
import success from '../images/sayImage.jpg';
import img from '../images/sayImage.jpg';
import { Link } from 'react-router-dom';

const PeopleSays = () => {

    const scroll = () =>{
        window.scrollTo(0,0);
    }
    return (
        <div className='peopleSay'>
            <div className='container peopleMain'>
                <div className='heading'>
                    <p className='title'>What People Say</p>
                    <p className='name'>Let's know what people think about us</p>
                </div>
                <div className='row  peoples'>
                    <div className='col-12 col-md-6 col-lg-4 col-xl-4 people'>
                        <p className='textPeople'>I have taken physiotherapy from different places, Olive's Physiotherapy is surely best among those"</p>
                        <div className='anotherSelf'>
                            <img className='anotherImage' src={success} alt="" />
                            <div className='infoPeople'>
                                <p className='name'>Abdur Rahman Hisham</p>
                                <small className='title'>Chairman of PIB djfkjf</small>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 col-xl-4 people'>
                        <p className='textPeople'>I have taken physiotherapy from different places, Olive's Physiotherapy is surely best among those" Lorem ipsum dolor sit amet consectetur adipisicing elit</p>
                        <div className='anotherSelf'>
                            <img className='anotherImage' src={success} alt="" />
                            <div className='infoPeople'>
                                <p className='name'>Abdur Rahman Hisham</p>
                                <small className='title'>Chairman of PIB djfkjf</small>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 col-xl-4 people'>
                        <p className='textPeople'>"Physiotherapy is essential treatment now a days and Olive's Physiotherapy is leading this sector with extra ordinary passion"</p>
                        <div className='anotherSelf'>
                            <img className='anotherImage' src={success} alt="" />
                            <div className='infoPeople'>
                                <p className='name'>Abdur Rahman Hisham</p>
                                <small className='title'>Chairman of PIB djfkjf</small>
                            </div>
                        </div>
                    </div>
                    {/* <div className='col-12 col-lg-4 people'>
                        <p className='textPeople'>"Services are excellent here in Olive's Physiotherapy"</p>
                        <div className='anotherSelf'>
                            <img className='anotherImage' src={success} alt="" />
                            <div className='infoPeople'>
                                <p className='name'>Abdur Rahman Hisham</p>
                                <small className='title'>Chairman of PIB djfkjf</small>
                            </div>
                        </div>
                    </div> */}
                </div>
                {/* <div className='peopleReview'>
                    <div className='title'>
                        <p>Client Review</p>
                    </div>
                    <div className='row'>
                        <div className='col-12 col-lg-4'>
                            <div className='reviewBox'>
                                <div className='clientSelf'>
                                    <div className='clientImg'>
                                        <img src={img} alt="" />
                                    </div>
                                    <div className='clientTitle'>
                                        <p className='name'>Md. Alman Khan lutfor rahman</p>
                                        <p className='title'>CEO & Founder</p>
                                    </div>
                                </div>
                                <div className='clientDesc'>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit tempora eaque minima ipsam minus similique tenetur atque distinctio, sunt iure?</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-4'>
                            <div className='reviewBox'>
                                <div className='clientSelf'>
                                    <div className='clientImg'>
                                        <img src={img} alt="" />
                                    </div>
                                    <div className='clientTitle'>
                                        <p className='name'>Md. Alman Khan lutfor rahman</p>
                                        <p className='title'>CEO & Founder</p>
                                    </div>
                                </div>
                                <div className='clientDesc'>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit tempora eaque minima ipsam minus similique tenetur atque distinctio, sunt iure?</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-4'>
                            <div className='reviewBox'>
                                <div className='clientSelf'>
                                    <div className='clientImg'>
                                        <img src={img} alt="" />
                                    </div>
                                    <div className='clientTitle'>
                                        <p className='name'>Md. Alman Khan lutfor rahman</p>
                                        <p className='title'>CEO & Founder</p>
                                    </div>
                                </div>
                                <div className='clientDesc'>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit tempora eaque minima ipsam minus similique tenetur atque distinctio, sunt iure?</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-4'>
                            <div className='reviewBox'>
                                <div className='clientSelf'>
                                    <div className='clientImg'>
                                        <img src={img} alt="" />
                                    </div>
                                    <div className='clientTitle'>
                                        <p className='name'>Md. Alman Khan lutfor rahman</p>
                                        <p className='title'>CEO & Founder</p>
                                    </div>
                                </div>
                                <div className='clientDesc'>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit tempora eaque minima ipsam minus similique tenetur atque distinctio, sunt iure?</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='clientMore'>
                       <Link onClick={scroll} to='/contact'> <button>More Review</button></Link>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default PeopleSays;