import React from 'react';
import ChooseUs from '../ServicePage/ChooseUs';
import OurPackages from '../ServicePage/OurPackages';
import TimeShedule from '../ServicePage/TimeShedule';
import AboutFounder from './AboutFounder';
import Banner from './Banner';
import Blog from './Blog';
import BlogBanner from '../BlogPages/BlogBanner';
import OurBenifi from './OurBenifi';
import OureTreatement from './OureTreatement';
import OurFeature from './OurFeature';
import OurQuality from './OurQuality';
import OurSuccess from './OurSuccess';
import OurTeam from './OurTeam';
import PeopleSays from './PeopleSays';
import Recoverd from './Recoverd';
import WeServe from './WeServe';
import SliderImages from './SliderImages';
import Contact from './Contact';

const HomeSections = () => {
    return (
        <div>
            <Banner></Banner>
            <OurQuality></OurQuality>
            <WeServe></WeServe>
            <TimeShedule></TimeShedule>
            <OurPackages></OurPackages>
            <ChooseUs></ChooseUs>
            <OurFeature></OurFeature>
            {/* <Recoverd></Recoverd>
            <SliderImages></SliderImages> */}
            {/* <OurSuccess></OurSuccess> */}
            <PeopleSays></PeopleSays>
            {/* <BlogBanner></BlogBanner> */}
            {/* <AboutFounder></AboutFounder> */}
            {/* <OurTeam></OurTeam>
            <OureTreatement></OureTreatement>
            <OurBenifi></OurBenifi>
            <Blog></Blog> */}
            <Contact></Contact>
        </div>
    );
};

export default HomeSections;