import React from 'react';

const JustGallery = () => {

    const justImages = [
        { img: 'https://cdn.pixabay.com/photo/2016/11/15/10/21/painting-1825855__340.jpg' },
        { img: 'https://cdn.pixabay.com/photo/2016/11/15/10/21/painting-1825855__340.jpg' },
        { img: 'https://cdn.pixabay.com/photo/2016/11/15/10/21/painting-1825855__340.jpg' },
        { img: 'https://cdn.pixabay.com/photo/2016/11/15/10/21/painting-1825855__340.jpg' },
        { img: 'https://cdn.pixabay.com/photo/2016/11/15/10/21/painting-1825855__340.jpg' },
        { img: 'https://cdn.pixabay.com/photo/2016/11/15/10/21/painting-1825855__340.jpg' },
        { img: 'https://cdn.pixabay.com/photo/2016/11/15/10/21/painting-1825855__340.jpg' },
        { img: 'https://cdn.pixabay.com/photo/2016/11/15/10/21/painting-1825855__340.jpg' },
    ]
    return (
        <div className='justGallery'>
            <div className='container'>
                <div className='row g-5'>
                    {
                        justImages.map(image => {
                            return (
                                <div className='col-12 col-lg-4'>
                                    <img src={image.img} alt="" />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default JustGallery;