import React from 'react';
import './ContactUs.css';
import action from '../images/icons/action.png';
import date from '../images/icons/date.png';
import man from '../images/icons/man.png';
import location from '../images/icons/contactLocation.png';
import { Link } from 'react-router-dom';

const ContactBanner = () => {
    return (
        <div className='contactBanner'>
            <div className='head'>
                <p className='name'>Book An Appointment</p>
            </div>
            {/* <div className='contactInfo'>
                <div className='infoName container'>
                    <div className='contactInfoBox'>
                       <div className='singleInfo'>
                       <img src={action} alt="" />
                        <select name="" id="" >
                            <option>Action</option>
                            <option>Shoulder injury</option>
                            </select>
                       </div>
                       <p>Shoulder injury</p>
                    </div>
                    <div className='contactInfoBox'>
                       <div className='singleInfo'>
                       <img src={date} alt="" />
                        <select name="" id="" >
                            <option>Date</option>
                            <option>Appointment date</option>
                            </select>
                       </div>
                       <p>Appointment date</p>
                    </div>
                    <div className='contactInfoBox'>
                       <div className='singleInfo'>
                       <img src={man} alt="" />
                        <select name="" id="" >
                            <option>Physiotherapist</option>
                            <option>Choose your doctor</option>
                            </select>
                       </div>
                       <p>Choose your doctor</p>
                    </div>
                    <div className='contactInfoBox'>
                       <div className='singleInfo'>
                       <img src={location} alt="" />
                        <select name="" id="" >
                            <option>Location</option>
                            <option>Shoulder injury</option>
                            </select>
                       </div>
                       <p>Your location</p>
                    </div>
                    <div className='contactButton'>
                        <Link to='/appointment'><button>Book An Appointment</button></Link>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default ContactBanner;