import React from 'react';
import './Footer.css';
import icon from '../images/icons/facebook.jpg';
import icon1 from '../images/icons/googlePluse.jpg';
import icon2 from '../images/icons/youtube.jpg';
import icon3 from '../images/icons/instagram.jpg';
import location from '../images/icons/location.png';
import message from '../images/icons/message.png';
import phone from '../images/icons/phone.png';

const Footer = () => {
    return (
        <div className='footer'>
            <div className='footerContents'>
                <div className='contents'>
                    <div className='socials'>
                        <div className='icons'>
                            <img src={icon} className='icon' alt="" />
                        </div>
                        <div className='icons'>
                            <img src={icon1} className='icon' alt="" />
                        </div>
                        <div className='icons'>
                            <img src={icon2} className='icon' alt="" />
                        </div>
                        <div className='icons'>
                            <img src={icon3} className='icon' alt="" />
                        </div>
                    </div>
                    <div className='footerText'>
                        <div className='row'>
                            <div className='col-12 col-lg-8'>
                                <div className='location'>
                                    <img src={location} alt="" />
                                    <p>Uttara (town) ; Uttara is located in Dhaka division. Uttara. Location of Uttara. Uttara is located in Bangladesh</p>
                                </div>
                            </div>
                            <div className='col-12 col-lg-4'>
                                <div className='info'>
                                    <img src={phone} alt="" />
                                    <p>+088 457477 7454</p>
                                </div>
                                <div className='info'>
                                    <img src={message} alt="" />
                                    <p>zerodevsltd@gmail.com</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Footer;