import React from 'react';
import { Link } from 'react-router-dom';
import icon1 from '../images/icons/incon (1).png'
import icon2 from '../images/icons/incon (2).png'
import icon3 from '../images/icons/incon (3).png'
import icon4 from '../images/icons/incon (4).png'
import icon5 from '../images/icons/incon (5).png'
import icon6 from '../images/icons/incon (6).png'

const ChooseUs = () => {

    const scroll = () =>{
        window.scrollTo(0,0);
    }
    return (
        <div className='chooseUs'>
            <div className='container'>
                <div className='heading'>
                    <p>Why <span>Choose</span> Us</p>
                </div>
                <div className='row'>
                    <div className='col-12 col-md-6 col-lg-4 col-xl-4'>
                        <div className="chooseBox">
                            <div className='chooseImage'>
                                <div className='imageDiv'>
                                    <img src={icon1} alt="" />
                                </div>
                            </div>
                            <div className='chooseContent'>
                                <div className="title">
                                    <p> Comfortable Environment</p>
                                </div>
                                <div className="text">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil saepe suscipit quos.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 col-xl-4'>
                        <div className="chooseBox">
                            <div className='chooseImage'>
                            <div className='chooseImage'>
                                <div className='imageDiv'>
                                <img src={icon2} alt="" />
                                </div>
                            </div>
                            </div>
                            <div className='chooseContent'>
                                <div className="title">
                                    <p> Comfortable Environment</p>
                                </div>
                                <div className="text">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil saepe suscipit quos.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 col-xl-4'>
                        <div className="chooseBox">
                            <div className='chooseImage'>
                            <div className='chooseImage'>
                                <div className='imageDiv'>
                                <img src={icon3} alt="" />
                                </div>
                            </div>
                            </div>
                            <div className='chooseContent'>
                                <div className="title">
                                    <p> Comfortable Environment</p>
                                </div>
                                <div className="text">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil saepe suscipit quos.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 col-xl-4'>
                        <div className="chooseBox">
                            <div className='chooseImage'>
                            <div className='chooseImage'>
                                <div className='imageDiv'>
                                <img src={icon4} alt="" />
                                </div>
                            </div>
                            </div>
                            <div className='chooseContent'>
                                <div className="title">
                                    <p> Comfortable Environment</p>
                                </div>
                                <div className="text">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil saepe suscipit quos.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 col-xl-4'>
                        <div className="chooseBox">
                            <div className='chooseImage'>
                            <div className='chooseImage'>
                                <div className='imageDiv'>
                                <img src={icon5} alt="" />
                                </div>
                            </div>
                            </div>
                            <div className='chooseContent'>
                                <div className="title">
                                    <p> Comfortable Environment</p>
                                </div>
                                <div className="text">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil saepe suscipit quos.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 col-xl-4'>
                        <div className="chooseBox">
                            <div className='chooseImage'>
                            <div className='chooseImage'>
                                <div className='imageDiv'>
                                <img src={icon6} alt="" />
                                </div>
                            </div>
                            </div>
                            <div className='chooseContent'>
                                <div className="title">
                                    <p> Comfortable Environment</p>
                                </div>
                                <div className="text">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil saepe suscipit quos.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='chooseButton'>
                   <Link onClick={scroll} to='/blog'> <button >Read More</button></Link>
                </div>
            </div>
        </div>
    );
};

export default ChooseUs;