import { Route, Routes } from "react-router-dom";
import AboutSection from "./component/AboutPage/AboutSection";
import Appointment from "./component/Appointment/Appointment";
import BlogSection from "./component/BlogPages/BlogSection";
import ContactUs from "./component/ContactUs/ContactUs";
import Footer from "./component/Footer/Footer";
import Banner2 from "./component/Gallery/Banner2";
import FlyerSection from "./component/Gallery/FlyerSection";
import GallerySection from "./component/Gallery/GallerySection";
import PatientSection from "./component/Gallery/PatientSection";
import HomeSections from "./component/HomePages/HomeSections";
import Navbar from "./component/Navbar/Navbar";
import ServiceSection from "./component/ServicePage/ServiceSection";
import TeamSection from "./component/TeamPage.js/TeamSection";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <Navbar></Navbar>
      <Routes>
        <Route path="/" element={<HomeSections></HomeSections>}></Route>
        <Route path="/about" element={<AboutSection></AboutSection>}></Route>
        <Route path="/team" element={<TeamSection></TeamSection>}></Route>
        <Route path="/service" element={<ServiceSection></ServiceSection>}></Route>
        <Route path="/gallery" element={<GallerySection></GallerySection>}></Route>
        <Route path="/work-place" element={<Banner2></Banner2>}></Route>
        <Route path="/paitaint-gallery" element={<PatientSection></PatientSection>}></Route>
        <Route path="/flyer" element={<FlyerSection></FlyerSection>}></Route>
        <Route path="/blog" element={<BlogSection></BlogSection>}></Route>
        <Route path="/contact" element={<ContactUs></ContactUs>}></Route>
        <Route path="/appointment" element={<Appointment></Appointment>}></Route>
      </Routes>
      <Footer></Footer>
      <ToastContainer />
    </div>
  );
}

export default App;
