import React, { useEffect } from "react";
import "./Navbar.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";

const NavbarComponent = () => {
  // useEffect(()=>{
  //     const main = document.getElementById("main");
  //     const navbar = document.getElementById("navbar");
  //     window.onscroll = function (){
  //         if(window.pageYOffset >= navbar.offsetTop){
  //             navbar.classList.add("sticky");
  //         }
  //         else{
  //             navbar.classList.remove("sticky");
  //         }
  //     }
  // },[])

  useEffect(() => {
    const sideMenus = document.querySelectorAll(".select");
    sideMenus.forEach((sideMenu) => {
      sideMenu.addEventListener("click", function () {
        sideMenus.forEach((lnk) => lnk.classList.remove("change"));
        this.classList.add("change");
      });
    });

    window.addEventListener('click', function (evt) {
      if (!evt.target.matches('.select') && (!evt.target.matches('.main-nav'))) {
          sideMenus.forEach((lnk) => lnk.classList.remove("change"));
          this.classList.add("change");
      };
    })

  }, []);

  const scroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="container">
      <div id="navbar" className="">
        <Navbar
          fixed="top"
          collapseOnSelect
          expand="lg"
          bg="white"
          variant="white"
          className="mainNav"
        >
          <Container>
            <Navbar.Brand
              as={Link}
              to={"/"}
              onClick={scroll}
              className="barNav"
            >
              ZERODEVS
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              className="barNav1"
            />
            <Navbar.Collapse className="navMenu" id="responsive-navbar-nav">
              <Nav className="me-auto"></Nav>
              <Nav>
                <Nav.Link
                  as={Link}
                  to="/"
                  onClick={scroll}
                  className="select pl-2"
                >
                  Home
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/about"
                  onClick={scroll}
                  className="select pl-2"
                >
                  About
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/team"
                  onClick={scroll}
                  className="select pl-2"
                >
                  Our Team
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/service"
                  onClick={scroll}
                  className="select pl-2"
                >
                  Services
                </Nav.Link>
                <NavDropdown title="Gallery" className="select pl-2" id="collasible-nav-dropdown">
                  <NavDropdown.Item
                    as={Link}
                    to="/gallery"
                    onClick={scroll}
                    className="select pl-2"
                  >
                    Our Gallery
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/work-place"
                    onClick={scroll}
                    className="select pl-2"
                  >
                    Work Place
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/paitaint-gallery"
                    onClick={scroll}
                    className="select pl-2"
                  >
                    Patient Gallery
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/flyer"
                    onClick={scroll}
                    className="select pl-2"
                  >
                    Flyers
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link
                  as={Link}
                  to="/blog"
                  onClick={scroll}
                  className="select pl-2"
                >
                  Blog
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/contact"
                  onClick={scroll}
                  className="select pl-2"
                >
                  Contact
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </div>
  );
};

export default NavbarComponent;
