import React from 'react';
import { Link } from 'react-router-dom';
import SliderImages from './SliderImages';

const Recoverd = () => {

    const scroll = () =>{
        window.scrollTo(0,0);
    }
    return (
        <div className='recover'>
            <div className='head'>
                <p className="title">Recoverd Patient Is Our Success</p>
            </div>
            <p className="text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit odio possimus alias facere distinctio deserunt, quasi quo ut blanditiis similique.</p>
            <div className='recoverd'>
                <div className='container'>
                    <div className='heading'>
                        <p>Have look at our <br /><span>patient</span></p>
                    </div>
                    <div className='content'>
                        <p className='text'>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                    </div>

                    <div className='recoverdButton'>
                       <Link onClick={scroll} to='/contact'> <button>
                            Join Us
                        </button></Link>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Recoverd;