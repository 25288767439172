import React from 'react';
import Recoverd from '../HomePages/Recoverd';
import JustGallery from './JustGallery';

const PatientSection = () => {
    return (
        <div>
            <Recoverd></Recoverd>
            <JustGallery></JustGallery>
        </div>
    );
};

export default PatientSection;