import React from 'react';
import serve from '../images/qualified.png';
const WeServe = () => {
    return (
        <div className='weServe'>
            <div className='container'>
                <div className='heading'>
                    <p className="name">-What We Serve</p>
                    <p className="title">Conditions We <span>Treat</span></p>
                    <p className="text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus, quod.
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus, quod.
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus, quod.</p>
                </div>
                <div className='conditionRows'>
                    <div className='row'>
                        <div className='col-12 col-md-6 col-lg-3 col-xl-3 offset-lg-2'>
                            <div className='conditionBox'>
                                <p className='text'> Mannually therapy</p>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-3 col-xl-3'>
                            <div className='conditionBox'>
                                <p className='text1'> Mannually therapy</p>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-3 col-xl-3'>
                            <div className='conditionBox'>
                                <p className='text'> MOVEMENT WITH MOBILIZATION</p>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-3 col-xl-3 offset-lg-2'>
                            <div className='conditionBox'>
                                <p className='text1'> Mannually therapy</p>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-3 col-xl-3'>
                            <div className='conditionBox'>
                                <p className='text'> MOVEMENT WITH MOBILIZATION</p>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-3 col-xl-3'>
                            <div className='conditionBox'>
                                <p className='text1'> Mannually therapy</p>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='serveContent'>
                    <div className='row'>
                        <div className='col-12 col-md-8 col-lg-8 col-xl-8'>
                            <p className='text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea ratione autem velit incidunt veritatis laudantium! Modi ea ipsam est consequatur autem perspiciatis voluptatem quibusdam. Enim quos rem inventore porro quibusdam.</p>
                            <div className='contentServe row'>
                                <div className='col-12 col-md-6 col-lg-6 col-xl-6'>
                                <div className='contentPoints'>
                                    <div>
                                        <p> <span>&rarr;</span> Lorem ipsum dolor sit amet</p>
                                        <p> <span>&rarr;</span> Lorem ipsum dolor sit amet</p>
                                        <p> <span>&rarr;</span> Lorem ipsum dolor sit amet</p>
                                        <p> <span>&rarr;</span> Lorem ipsum dolor sit amet</p>
                                        <p> <span>&rarr;</span> Lorem ipsum dolor sit amet</p>
                                        <p> <span>&rarr;</span> Lorem ipsum dolor sit amet</p>
                                        <p> <span>&rarr;</span> Lorem ipsum dolor sit amet</p>
                                    </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 col-lg-6 col-xl-6'>
                                    <div className="contentPoints">
                                    <div>
                                        <p> <span>&rarr;</span> Lorem ipsum dolor sit amet</p>
                                        <p> <span>&rarr;</span> Lorem ipsum dolor sit amet</p>
                                        <p> <span>&rarr;</span> Lorem ipsum dolor sit amet</p>
                                        <p> <span>&rarr;</span> Lorem ipsum dolor sit amet</p>
                                        <p> <span>&rarr;</span> Lorem ipsum dolor sit amet</p>
                                        <p> <span>&rarr;</span> Lorem ipsum dolor sit amet</p>
                                        <p> <span>&rarr;</span> Lorem ipsum dolor sit amet</p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            {/* <p className='searchText'>
                                Dont see your conditions? Find it here.
                            </p> */}
                            {/* <div className='serveInput'>
                                <input type="text" />
                                <button>Search</button>
                            </div> */}
                        </div>
                        <div className='serveImage col-12 col-md-4 col-lg-4 col-xl-4'>
                           <div className='weImage'>
                           <img src={serve} alt="" />
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WeServe;