import React, { useRef, useState } from "react";
import "./Appointment.css";
import action from "../images/icons/action.png";
import date from "../images/icons/date.png";
import man from "../images/icons/man.png";
import location from "../images/icons/contactLocation.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

const Appointment = () => {
    const [startDate,setStartDate] = useState(new Date());
    // message send on Email
    const form = useRef();
    const navigate = useNavigate();

    const scroll = () =>{
      window.scrollTo(0,0);
  }


    const sendEmail = (e) => {

      e.preventDefault();

      emailjs.sendForm('service_cg0fgta', 'template_vxk58bg', form.current, 'M6vHaULS1dcMtZujn')
      .then((result) => {
        toast('Your appointment request is submitted successfully. We will call you in a short time for confirmation ');
        e.target.reset();
        navigate('/');
        scroll();
      },(error) => {
        alert(error.text);
      }
      )
    }

  return (
    <div className="appointment">
      <div className="container">
        <div className="head">
          <p>Appointment Form</p>
        </div>
        <div className="appointmentForm">
          <form ref={form} onSubmit={sendEmail}>
            <div className="">
              <label> &nbsp; I am Registering for</label>
              <div className="checkPatient">
                <div className="checkInput">
                  <input type="radio" id="myself" name="patient" value="myself" checked/>
                  <label for='myself'>Myself</label>
                </div>
                <div className="checkInput">
                  <input type="radio" id="others" name="patient" value="others" />
                  <label for='others'>Others</label>
                </div>
              </div>
            </div>
            <div className="field">
              <label>Patient's Full Name <span>*</span></label>
              <input type="text" name="user_name" required/>
            </div>
            <div className="field">
              <p>Mobile Number (Mandatory) <span>*</span> :</p>
              <label>
                Notifications for appointment and remainders will be sent to
                this number
              </label>
              <input type="tel" pattern="[01][0-9]{10}" name="phone" required/>
            </div>
            <div className="field">
              <label>E-mail (optional)</label>
              <input type="text" name="user_email"/>
            </div>
            <div className="field">
              <label>Message (optional)</label>
              <div>
              <textarea className="messageField" name='message'></textarea>
              </div>
            </div>
            <div className="categoryTime">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="appointmentInfo">
                    <div className="infoImage">
                      <img src={date} alt="" />
                      <p>Appointment Date</p>
                    </div>
                    {/* <select name="" id="">
                      <option>Date</option>
                    </select> */}
                    <div className="dateArrow">
                    <DatePicker className="datePicker" 
                    name='date'
                    arrow={true} selected={startDate} 
                    dateFormat='MMMM d, yyyy' onChange={(date) => setStartDate(date)} ></DatePicker>
                    <span>&#8964;</span>
                    </div>
                  </div>
                </div>
                {/* <div className="col-12 col-lg-6">
                  <div className="appointmentInfo">
                    <div className="infoImage">
                      <img src={action} alt="" />
                      <p>Action</p>
                    </div>
                    <select name="" id="">
                      <option>Action</option>
                      <option>Shoulder injury</option>
                    </select>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="appointmentInfo">
                    <div className="infoImage">
                      <img src={man} alt="" />
                      <p>Physiotherapist</p>
                    </div>
                   <div className="options">
                   <select name="" id="">
                      <option>Physiotherapist</option>
                      <option>Shoulder injury</option>
                    </select>
                   </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="appointmentInfo">
                    <div className="infoImage">
                      <img src={location} alt="" />
                      <p>Location</p>
                    </div>
                    <select name="" id="">
                      <option>Location</option>
                      <option>Shoulder injury</option>
                    </select>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="appointButton">
            <button type="submit">Book Appointment &rarr;</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Appointment;
