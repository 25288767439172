import React from 'react';
import img from '../images/doctor.jpg';
import img1 from '../images/reviewImage.jpg';

const OurQuality = () => {
    return (
        <div className='ourQuality'>
            <div className='container'>
                <div className='head'>
                    <p className='title'>High <span>Qualified </span> Therapist</p>
                    <p className='text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas id eius unde mollitia possimus illum. Vero natus nisi sint illo Vero natus nisi sint illo Vero natus nisi sint illo.</p>
                </div>
                <div className='row'>
                    <div className='col-12 col-md-4 col-lg-4 col-xl-4'>
                        <div className='headImage'>
                            <img src={img} alt="" />
                        </div>
                    </div>
                    <div className='col-12 col-md-8 col-lg-8 col-xl-8'>
                        <div className='therapy'>
                            <p className="title">Founder and head of zero physiotherapy</p>
                            <p className='desc'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nemo veritatis est quia. Mollitia at illum fugit magni ex quasi facilis, quo fugiat impedit deserunt repellendus dolorem nostrum ipsum nemo fuga? Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nemo veritatis est quia. Mollitia at illum fugit magni ex quasi facilis, quo fugiat impedit deserunt repellendus dolorem nostrum ipsum nemo fuga? Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nemo veritatis est quia. Mollitia at illum fugit magni ex quasi facilis, quo fugiat impedit deserun</p>
                        </div>
                    </div>
                </div>
                <div className='qualityColumns'>
                    <div className='row'>
                        <div className='col-6 col-md-6 col-lg-3 col-xl-3'>
                            <div className='qualitySelf'>
                                <div className='qualityImg'>
                                    <img src={img1} alt="" />
                                </div>
                                <div className='qualityTitle'>
                                    <p className='name'>Md. Alman Khan</p>
                                    <p className='title'>CEO & Founder</p>
                                </div>
                            </div>

                        </div>
                        <div className='col-6 col-md-6 col-lg-3 col-xl-3'>
                            <div className='qualitySelf'>
                                <div className='qualityImg'>
                                    <img src={img1} alt="" />
                                </div>
                                <div className='qualityTitle'>
                                    <p className='name'>Md. Alman Khan</p>
                                    <p className='title'>CEO & Founder</p>
                                </div>
                            </div>

                        </div>
                        <div className='col-6 col-md-6 col-lg-3 col-xl-3'>
                            <div className='qualitySelf'>
                                <div className='qualityImg'>
                                    <img src={img1} alt="" />
                                </div>
                                <div className='qualityTitle'>
                                    <p className='name'>Md. Alman Khan</p>
                                    <p className='title'>CEO & Founder</p>
                                </div>
                            </div>

                        </div>
                        <div className='col-6 col-md-6 col-lg-3 col-xl-3'>
                            <div className='qualitySelf'>
                                <div className='qualityImg'>
                                    <img src={img1} alt="" />
                                </div>
                                <div className='qualityTitle'>
                                    <p className='name'>Md. Alman Khan</p>
                                    <p className='title'>CEO & Founder</p>
                                </div>
                            </div>

                        </div>
                        <div className='col-6 col-md-6 col-lg-3 col-xl-3'>
                            <div className='qualitySelf'>
                                <div className='qualityImg'>
                                    <img src={img1} alt="" />
                                </div>
                                <div className='qualityTitle'>
                                    <p className='name'>Md. Alman Khan</p>
                                    <p className='title'>CEO & Founder</p>
                                </div>
                            </div>

                        </div>
                        <div className='col-6 col-md-6 col-lg-3 col-xl-3'>
                            <div className='qualitySelf'>
                                <div className='qualityImg'>
                                    <img src={img1} alt="" />
                                </div>
                                <div className='qualityTitle'>
                                    <p className='name'>Md. Alman Khan</p>
                                    <p className='title'>CEO & Founder </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-6 col-md-6 col-lg-3 col-xl-3'>
                            <div className='qualitySelf'>
                                <div className='qualityImg'>
                                    <img src={img1} alt="" />
                                </div>
                                <div className='qualityTitle'>
                                    <p className='name'>Md. Alman Khan</p>
                                    <p className='title'>CEO & Founder</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-6 col-md-6 col-lg-3 col-xl-3'>
                            <div className='qualitySelf'>
                                <div className='qualityImg'>
                                    <img src={img1} alt="" />
                                </div>
                                <div className='qualityTitle'>
                                    <p className='name'>Md. Alman Khan</p>
                                    <p className='title'>CEO & Founder</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default OurQuality;