import React from 'react';
import find from '../images/FindImage.jpg';
const WhereFind = () => {
    return (
        <div className='whereFind'>
            <div className='heading'>
                <p className='text'>where to find us</p>
            </div>
            <div className='whereImage'>
                <img src={find} alt="" />
            </div>
            <div className='whereContent'>
                <div className='allContent'>
                    <p className='text'>Lorem ipsum dolor sit amet consectetur adipisicing elit</p>
                    <p className="title">Address :</p>
                    <p className='desc'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Distinctio, repellendus.</p>
                    <p className="contact"><b>Contact for us&nbsp; </b>:&nbsp; 47857438</p>
                </div>
            </div>
        </div>
    );
};

export default WhereFind;